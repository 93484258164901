import React, { Component } from 'react';
import classes from './SectionC.module.css';
import mapa from '../../assets/img/mapa.png';

class SectionC extends Component {

    render() {
        return (
            <section id="localizacao" className={classes.section}>
                <div className={classes.container}>
                    <div className={classes.imageContainer}>
                        <a href="#a">
                            <div className={classes.imageWrapper}>
                                <img src={mapa} alt="Logo" />
                            </div>
                        </a>
                    </div>
                    <div className={classes.column}>
                        <h1 className={classes.title}>Onde estamos localizados?</h1>
                        <div className={classes.decorator}></div>
                        <p className={classes.text}>O CafeWorking é um coworking localizado na região nobre de Belo Horizonte, no Condomínio Parque Avenida, na Avenida Raja Gabaglia, nº 2.000, no 9º Andar. Estamos instalados em um dos mais modernos prédios comerciais da capital mineira. O CafeWorking conta com estacionamento para melhor atender seus clientes </p>
                    </div>
                </div>
            </section>
        );
    }
}

export default SectionC;