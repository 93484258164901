import React, { Component } from 'react';
import classes from './SectionA.module.css';

class SectionA extends Component {

    render() {
        return (
            <section id="A" className={classes.section}>
                <div className={classes.background}>
                    <div className={classes.backgroundFilter}>
                        <div className={classes.container}>
                            <div className={classes.textContainer}>
                                <h1 className={classes.title}>Café e Coworking, uma mistura especial!</h1>
                                <p className={classes.subtitle}>O CafeWorking oferece uma infraestrutura completa de coworking e também uma cafeteria exclusiva.</p>
                            </div>
                            <div className={classes.buttonsContainer}>
                                <button className={classes.btn} onClick={this.props.openForm}>Solicite um contato</button>
                                <a className={classes.btn} target="_blank" rel="noreferrer" href="https://admin.grupociatos.com.br/">Já sou cliente CW</a>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        );
    }
}

export default SectionA;