import { formatDateFromWordpress } from '../util/DateUtil.js'

const BASE_URL = process.env.REACT_APP_API_URL;

export const getArticles = async () => {
    return fetch(`${BASE_URL}/posts?_embed`)
        .then(res => res.json())
        .catch(err => console.log('Error: ', err));
}

export const getArticleById = async (id) => {
    return fetch(`${BASE_URL}/posts/${id}?_embed`)
        .then(res => res.json())
        .catch(err => console.log('Error: ', err))
}

export async function getArticle(id) {
    return fetch(`${BASE_URL}/posts/${id}?_embed`)
        .then(res => res.json())
        .then(async data => {
            const url = data._embedded.hasOwnProperty('wp:featuredmedia')
                ? data._embedded['wp:featuredmedia'][0].source_url
                : undefined
            return {
                id: data.id,
                title: data.title.rendered,
                excerpt: data.excerpt.rendered,
                date: formatDateFromWordpress(data.date),
                imgUrl: url,
                author: data._embedded.author[0].name,
                slug: data.slug,
            }
        })
}

export async function getArticleBySlug(slug) {
    const url = `${BASE_URL}/posts?slug=${slug}&_embed`
    return fetch(url)
        .then(res => res.json())
        .then(data => data[0])
        .then(async data => {
            const url = data._embedded.hasOwnProperty('wp:featuredmedia')
                ? data._embedded['wp:featuredmedia'][0].source_url
                : undefined
            return {
                id: data.id,
                title: data.title.rendered,
                date: formatDateFromWordpress(data.date),
                imgUrl: url,
                author: data._embedded.author[0].name,
                authorAvatar: data._embedded.author[0].avatar_urls[96],
                content: data.content.rendered,
                categories: data.categories,
                categoryName: data._embedded['wp:term'][0][0].name,
                selfLink: `http://ciatoscoworking.com.br/blog/article/${slug}`
            }
        })
        .catch(err => console.error('Error: ', err))
}

export async function getArticlesFilteredFields() {
    const url = `${BASE_URL}/posts?per_page=12&_embed`
    return fetchArticles(url)
}

export async function getArticlesByCategoryId(categoryId) {
    const url = `${BASE_URL}/posts?per_page=12&categorias=${categoryId}&_embed`
    return fetchArticles(url)
}

export async function getArticlesBySegmentId(segmentId) {
    const url = `${BASE_URL}/posts?per_page=12&segmentos=${segmentId}&_embed`
    return fetchArticles(url)
}

export async function searchArticles(word) {
    const url = `${BASE_URL}/search?per_page=12&search=${word}`
    return fetchArticlesForSearch(url)
}

async function fetchArticles(url) {
    return fetch(url)
        .then(res => res.json())
        .then(data => {
            return data.map(item => {
                const url = item._embedded.hasOwnProperty('wp:featuredmedia')
                    ? item._embedded['wp:featuredmedia'][0].source_url
                    : undefined
                return {
                    id: item.id,
                    title: item.title.rendered,
                    date: formatDateFromWordpress(item.date),
                    imgUrl: url,
                    author: item._embedded.author[0].name,
                    excerpt: item.excerpt.rendered.replaceAll('<p>', '').replaceAll('</p>', '').replaceAll('\n', ''),
                    slug: item.slug,
                }
            })
        })
        .catch(err => console.error('Error: ', err))
}

async function fetchArticlesForSearch(url) {

    const data = await fetch(url)
        .then(res => res.json())
        .catch(err => console.error('Error: ', err))

    const articles = []

    for (let item of data) {
        const article = await getArticle(item.id)
        articles.push(article)
    }

    return articles
}