import React, { Component } from "react";
import '../../main.css';
import classes from "./MainPage.module.css";
import Capa from "../capa/Capa";
import Subscriber from "../subscriber/Subscriber";
import { getArticles } from "../../services/articleService.js";
import WhatsappLink from '../../landing-page/whatsapp-link/WhatsappLink';
import Footer from "../footer/Footer";
import Form from "../../landing-page/form/Form";
import FeaturedLinks from "../featured-links/FeaturedLinks";
import ArticleList from "../article-list/ArticleList";
import Slider from "../slider/Slider";
import Header from "../header/Header";

class MainPage extends Component {

    constructor() {
        super();
        this.state = {
            articles: [],
        };
    }

    async componentDidMount() {

        getArticles()
            .then(result => {
                this.setState({ articles: result });
            })
    }

    render() {
        const { articles } = this.state;
        if (articles === undefined || articles === null) {
            return <div>Loading...</div>
        }
        return (
            <div className="home-page">

                <Header />
                <WhatsappLink />
                <FeaturedLinks color='brown' />
                <Capa />
                <div className={classes.sectionA}>
                    <Slider articles={articles} />
                    <div className={classes.subscriberContainer}>
                        <Subscriber />
                    </div>
                </div>

                <div className={classes.main}>
                    <p className={classes.mt}>Artigos recentes</p>
                    <ArticleList articles={articles} />
                </div>

                <Form color='brown' />

                <footer className={classes.footer}>
                    <Footer />
                </footer>
            </div>
        );
    }
}

export default MainPage;