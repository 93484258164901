import React, { Component } from "react";
import MainPage from "../components/main-page/MainPage";
import ArticlePage from "../components/article-page/ArticlePage";
import { BrowserRouter as Router, Switch, Route } from "react-router-dom";
import Home from "../landing-page/home/Home";
import Category from "../pages/category/Category";
import Segment from "../pages/segment/Segment";
import Search from "../pages/search/Search";

class App extends Component {
  render() {
    return (
      <Router>
        <Switch>
          <Route path="/" exact component={Home} />
          <Route path="/blog" exact component={MainPage} />
          <Route path="/blog/article/:id" component={ArticlePage} />
          <Route path="/blog/categories/:id" component={Category} />
          <Route path="/blog/segments/:id" component={Segment} />
          <Route path="/blog/search/:word" component={Search} />
        </Switch>
      </Router>
    );
  }
}

export default App;
