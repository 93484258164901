import React, { Component } from "react";
import s from './ModalFotosEspacos.module.css';
import leftIcon from '../../assets/img/icons/arrow-left.svg';
import rightIcon from '../../assets/img/icons/arrow-right.svg';

// cafeteria
import cafeteria01 from '../../assets/img/cafeteria/01.jpeg';
import cafeteria02 from '../../assets/img/cafeteria/02.jpeg';
import cafeteria03 from '../../assets/img/cafeteria/03.jpeg';
import cafeteria04 from '../../assets/img/cafeteria/04.jpeg';
import cafeteria05 from '../../assets/img/cafeteria/05.jpeg';
import cafeteria06 from '../../assets/img/cafeteria/06.jpeg';
import cafeteria07 from '../../assets/img/cafeteria/07.jpeg';
import cafeteria08 from '../../assets/img/cafeteria/08.jpeg';
import cafeteria09 from '../../assets/img/cafeteria/09.jpeg';
import cafeteria10 from '../../assets/img/cafeteria/10.jpeg';
import cafeteria11 from '../../assets/img/cafeteria/11.jpeg';
import cafeteria12 from '../../assets/img/cafeteria/12.jpeg';

// lounge
import lounge01 from '../../assets/img/lounge/01.jpeg';
// import lounge02 from '../../assets/img/lounge/02.jpeg';
import lounge03 from '../../assets/img/lounge/03.jpeg';
import lounge04 from '../../assets/img/lounge/04.jpeg';
import lounge05 from '../../assets/img/lounge/05.jpeg';
import lounge06 from '../../assets/img/lounge/06.jpeg';
// import lounge07 from '../../assets/img/lounge/07.jpeg';

// salas compartilhadas
import compartilhada01 from '../../assets/img/salas-compartilhadas/01.jpeg';
import compartilhada02 from '../../assets/img/salas-compartilhadas/02.jpeg';
import compartilhada03 from '../../assets/img/salas-compartilhadas/03.jpeg';
// import compartilhada04 from '../../assets/img/salas-compartilhadas/04.jpeg';
import compartilhada05 from '../../assets/img/salas-compartilhadas/05.jpeg';
import compartilhada06 from '../../assets/img/salas-compartilhadas/06.jpeg';
import compartilhada07 from '../../assets/img/salas-compartilhadas/07.jpeg';
// import compartilhada08 from '../../assets/img/salas-compartilhadas/08.jpeg';
// import compartilhada09 from '../../assets/img/salas-compartilhadas/09.jpeg';
import compartilhada10 from '../../assets/img/salas-compartilhadas/10.jpeg';
// import compartilhada11 from '../../assets/img/salas-compartilhadas/11.jpeg';

// salas privativas
import privativa01 from '../../assets/img/salas-privativas/01.jpeg';
import privativa02 from '../../assets/img/salas-privativas/02.jpeg';
import privativa03 from '../../assets/img/salas-privativas/03.jpeg';
import privativa04 from '../../assets/img/salas-privativas/04.jpeg';
import privativa05 from '../../assets/img/salas-privativas/05.jpeg';
import privativa06 from '../../assets/img/salas-privativas/06.jpeg';
import privativa07 from '../../assets/img/salas-privativas/07.jpeg';
import privativa08 from '../../assets/img/salas-privativas/08.jpeg';
import privativa09 from '../../assets/img/salas-privativas/09.jpeg';
import privativa10 from '../../assets/img/salas-privativas/10.jpeg';
import privativa11 from '../../assets/img/salas-privativas/11.jpeg';

// salas reuniao
import reuniao01 from '../../assets/img/salas-reuniao/01.jpeg';
import reuniao02 from '../../assets/img/salas-reuniao/02.jpeg';
import reuniao03 from '../../assets/img/salas-reuniao/03.jpeg';
import reuniao04 from '../../assets/img/salas-reuniao/04.jpeg';
import reuniao05 from '../../assets/img/salas-reuniao/05.jpeg';

class ModalFotosEspacos extends Component {

    constructor(props) {
        super(props);
        this.imgListCafeteria = [cafeteria01, cafeteria02, cafeteria03, cafeteria04, cafeteria05, cafeteria06, cafeteria07, cafeteria08, cafeteria09, cafeteria10, cafeteria11, cafeteria12];
        this.imgListLounge = [lounge01, lounge03, lounge04, lounge05, lounge06];
        this.imgListCompartilhdada = [compartilhada01, compartilhada02, compartilhada03, compartilhada05, compartilhada06, compartilhada07, compartilhada10];
        this.imgListPrivativa = [privativa01, privativa02, privativa03, privativa04, privativa05, privativa06, privativa07, privativa08, privativa09, privativa10, privativa11];
        this.imgListReuniao = [reuniao01, reuniao02, reuniao03, reuniao04, reuniao05];
        this.images = [this.imgListLounge, this.imgListPrivativa, this.imgListCompartilhdada, this.imgListReuniao, this.imgListCafeteria,]
        this.state = {
            selectedAba: 0,
            selectedImage: 0,
            selectedImagelist: this.images[this.props.index],
            left: false,
            right: true,
        }
    }

    onRight = () => {
        if (this.images[this.props.index].length - 1 > this.state.selectedImage) {
            this.setState((state, props) => ({
                selectedImage: state.selectedImage + 1,
                right: true,
            }))
        }
    }

    onLeft = () => {
        if (this.state.selectedImage > 0) {
            this.setState((state, props) => ({
                selectedImage: state.selectedImage - 1,
                left: true,
            }))
        }
    }

    showModal = () => {
        document.body.style.overflowY = 'hidden';
    }

    hideModal = () => {
        document.body.style.overflowY = 'scroll';
        const modal = document.getElementById('modalFotosEspacos');
        modal.style.display = 'none';
        this.setState({selectedImage: 0})
    }

    render() {
        const list = this.images[this.props.index];
        const loading = <div>Carregando...</div>
        if (!list) {
            return loading;
        }
        return (
            <div id="modalFotosEspacos" className={s.transparent}>
                <div className={s.frame}>

                    <div className={s.imgWrapper}>
                        <img src={list[this.state.selectedImage]} alt="Carrousel" />
                    </div>

                    <button className={s.btnClose} onClick={this.hideModal}>X</button>
                    <button className={s.btnArrow} onClick={this.onLeft}> <img src={leftIcon} alt="Left arrow" /> </button>
                    <button className={s.btnArrow} onClick={this.onRight}> <img src={rightIcon} alt="Right arrow" /> </button>

                </div>
            </div>
        )
    }
}

export default ModalFotosEspacos;