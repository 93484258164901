import React, { Component } from 'react'
import './style.css'
import arrowDown from '../../assets/img/icons/arrow-down.svg'
import menuIcon from '../../assets/img/icons/menu.svg'

class MenuMobile extends Component {
    constructor() {
        super()
        this.state = {
            category: 'hide',
            segment: 'hide',
            ciatos: 'hide',
            menu: 'hide'
        }
    }

    toggleDropdown = (param) => {
        const p = this.state[param]
        this.setState({
            [param]: p ? '' : 'hide'
        })
    }

    toggleMenu = (param) => {
        this.toggleDropdown(param)
    }

    render() {
        return (
            <div className="menu-mobile">
                <button className="btn-mobile" onClick={() => this.toggleMenu('menu')}>
                    <img className="btn-icon" src={menuIcon} alt="Menu Mobile" />
                </button>
                <div className={`shadow ${this.state.menu}`}>
                    <div className="menu">
                        <a className="menu-item" href=".">Home</a>
                        <button
                            className="menu-item"
                            onClick={() => this.toggleDropdown('category')}>Categorias &nbsp;
                            <img className="arrow-down" src={arrowDown} alt="Seta para baixo" />
                        </button>
                        <div className={`dropdown-mobile ${this.state.category}`}>
                            {
                                this.props.categories.map(c => {
                                    return <a className="dropdown-link-mobile" key={c.id} href={`/blog/categories/${c.id}`}>{c.name}</a>
                                })
                            }
                        </div>
                        <button
                            className="menu-item"
                            onClick={() => this.toggleDropdown('segment')}>Segmentos &nbsp;
                            <img className="arrow-down" src={arrowDown} alt="Seta para baixo" />
                        </button>
                        <div className={`dropdown-mobile ${this.state.segment}`}>
                            {
                                this.props.segments.map(s => {
                                    return <a className="dropdown-link-mobile" key={s.id} href={`/blog/segments/${s.id}`}>{s.name}</a>
                                })
                            }
                        </div>
                        <button
                            className="menu-item"
                            onClick={() => this.toggleDropdown('ciatos')}>Grupo Ciatos &nbsp;
                            <img className="arrow-down" src={arrowDown} alt="Seta para baixo" />
                        </button>
                        <div className={`dropdown-mobile ${this.state.ciatos}`}>
                            <a className="dropdown-link-mobile" href="/#cafeworking">Sobre o CafeWorking</a>
                            <a className="dropdown-link-mobile" href="/#cafeteria">Cafeteria</a>
                            <a className="dropdown-link-mobile" href="/#coworking">Coworking</a>
                            <a className="dropdown-link-mobile" href="/#contabilidade">Contabilidade</a>
                            <a className="dropdown-link-mobile" href="/#consultoria">Consultoria</a>
                            <a className="dropdown-link-mobile" href="/#nossos-espacos">Nossos Espaços</a>
                            <a className="dropdown-link-mobile" href="/#localizacao">Onde Estamos</a>
                        </div>
                    </div>
                </div>
            </div>
        )
    }
}

export default MenuMobile