import React, { Component } from 'react';
import style from './Capa.module.css';

class Capa extends Component {

    render() {
        return (
            <React.Fragment>
                <div className={style.mainContainer}>
                    <div className={style.backgroundFilter}>
                        <div className={style.titleContainer}>
                            <h1 className={style.title}>
                                Conteúdo para Pequenos e Médios Empreendedores
                        </h1>
                            <h2 className={style.subtitle}>
                                Nosso propósito é ajudar no crescimento de pequenas e médias empresas.
                        </h2>
                        </div>

                        <div className={style.row}>
                            <div className={style.card}>
                                <h3 className={style.cardTitle}>
                                    Conheça o CafeWorking
                                </h3>
                                <p className={style.text}>
                                    O CafeWorking oferece um espaço de coworking para o seu negócio, com a vantagem de uma cafateria exclusiva
                                </p>
                                <button className={style.btn}>Saiba Mais</button>
                            </div>

                            <div className={style.card}>
                                <h3 className={style.cardTitle}>
                                    Receba nossos conteúdos
                                </h3>
                                <p className={style.text}>
                                    Receba em primeira mão nossos artigos e fique por dentro  de todas as novidades
                                </p>
                                <button className={style.btn}>Assine</button>
                            </div>
                        </div>
                    </div>
                </div>
            </React.Fragment>
        );
    }
}

export default Capa;