import React, { Component } from 'react';
import style from './Navbar.module.css';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faBars } from '@fortawesome/free-solid-svg-icons';
import logo from '../../assets/img/logo.png';
import logoWhite from '../../assets/img/logo-white.png';
import classes from './Navbar.module.css';

class Navbar extends Component {

    scrollpos = window.pageYOffset;
    previousScrollpos = window.pageYOffset;

    constructor() {
        super();
        this.state = {
            navbarLogo: logoWhite,
            navbarStyle: style.navbarTransparent
        }
    }

    componentDidMount() {
        window.addEventListener('scroll', this.listenToScroll);
    }

    openForm = () => {
        const form = document.getElementById('form');
        form.style.display = 'unset';
    }

    listenToScroll = () => {
        const windowScrollPosition = window.pageYOffset;

        if (windowScrollPosition > 100) {
            this.setState({
                navbarLogo: logo,
                navbarStyle: style.navbar,
            })
        } else {
            this.setState({
                navbarLogo: logoWhite,
                navbarStyle: style.navbarTransparent,
            })
        }
    }

    toggleMenu(id, keepOpen) {
        const element = document.getElementById(id);
        let display = element.style.display;

        if (display === '' || display === 'flex') {
            element.style.display = 'none';
        }

        if (display === 'none' || keepOpen) {
            element.style.display = 'flex';
        }
    }

    render() {
        return (
            <React.Fragment>
                <nav id="header" className={this.state.navbarStyle}>
                    <div className={classes.content}>
                        <a href="." style={{ maxHeight: '70px' }}>
                            <img className={style.brandLogo} src={this.state.navbarLogo} alt="Logo" />
                        </a>

                        {/* 
                        Menu for large devices
                    */}
                        <ul className={style.navMenu}>

                            <li className={style.menuItem}>
                                <a href="#cafeworking" className={`${style.menuLink} `}>CAFEWORKING</a>
                            </li>

                            <li className={style.menuItem}>
                                <a href="#cafeteria" className={`${style.menuLink} `}>A CAFETERIA</a>
                            </li>

                            <li className={style.menuItem}>
                                <a href="#coworking" className={`${style.menuLink} `}>COWORKING</a>
                            </li>

                            <li className={style.menuItem}>
                                <a href="#contabilidade" className={`${style.menuLink} `}>CONTABILIDADE</a>
                            </li>

                            <li className={style.menuItem}>
                                <a href="#consultoria" className={`${style.menuLink} `}>CONSULTORIA</a>
                            </li>

                            <li className={style.menuItem}>
                                <a href="#nossos-espacos" className={`${style.menuLink} `}>FOTOS</a>
                            </li>

                            <li className={style.menuItem}>
                                <a href="#localizacao" className={`${style.menuLink} `}>LOCALIZAÇÃO</a>
                            </li>

                            <li className={style.menuItem}>
                                <a href="/blog" className={`${style.menuLink} `}>BLOG</a>
                                {/* <a href="http://cafeworking.com.br/blog" className={`${style.menuLink} `}>BLOG</a> */}
                            </li>
                        </ul>

                        <div className={style.btnMobile} onClick={() => { this.toggleMenu('mobile', false) }}>
                            <FontAwesomeIcon icon={faBars} />
                        </div>
                    </div>

                </nav>

                {/* 
                    Menu for mobile devices
                */}
                <div id="mobile" className={style.menuMobile} style={{ 'display': 'none' }}>
                    <a href="#cafeworking" className={`${style.menuLinkMobile}`} style={{ margin: '0' }}>CAFEWORKING</a>
                    <a href="#cafeteria" className={`${style.menuLinkMobile}`} style={{ margin: '0' }}>A CAFETERIA</a>
                    <a href="#coworking" className={`${style.menuLinkMobile}`} style={{ margin: '0' }}>COWORKING</a>
                    <a href="#contabilidade" className={`${style.menuLinkMobile}`} style={{ margin: '0' }}>CONTABILIDADE</a>
                    <a href="#consultoria" className={`${style.menuLinkMobile}`} style={{ margin: '0' }}>CONSULTORIA</a>
                    <a href="#nossos-espacos" className={`${style.menuLinkMobile}`} style={{ margin: '0' }}>FOTOS</a>
                    <a href="#localizacao" className={`${style.menuLinkMobile}`} style={{ margin: '0' }}>LOCALIZAÇÂO</a>
                    <a href="/blog" className={`${style.menuLinkMobile}`} style={{ margin: '0' }}>BLOG</a>
                    {/* <a href="http://cafeworking.com.br/blog" className={`${style.menuLinkMobile}`} style={{ margin: '0' }}>BLOG</a> */}
                </div>
            </React.Fragment>
        );
    }
}

export default Navbar;