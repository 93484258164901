import React, { Component } from 'react';
import classes from './SectionF.module.css';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
    faChartBar, faMapMarkedAlt, faEnvelope, faUsers, faBriefcase, faWifi,
    faKey, faPhone, faCalendarCheck, faMobileAlt,
    faUserCheck, faBullhorn, faDoorOpen, faCheck
} from '@fortawesome/free-solid-svg-icons';

class SectionF extends Component {

    render() {
        return (
            <section id="coworking" className={classes.section}>
                <div className={classes.container}>
                    <h2 className={classes.title}>QUAIS OS SERVIÇOS DO COWORKING?</h2>
                    <div className={classes.decorator}></div>
                    <p className={classes.subtitle}>Temos, em um só lugar, tudo o que é preciso para proporcionar o ambiente de trabalho ideal:</p>

                    <div className={classes.cards}>

                        <div className={classes.card}>
                            <h2 className={classes.cardTitle}>Escritório Virtual</h2>
                            <div className={classes.preco}>
                                R$<span>149,00</span>/mês
                            </div>
                            <div className={classes.cardBody}>
                                <div className={classes.row}>
                                    <FontAwesomeIcon icon={faChartBar} className={classes.icon} />
                                    Endereço Fiscal
                                </div>
                                <div className={classes.row}>
                                    <FontAwesomeIcon icon={faMapMarkedAlt} className={classes.icon} />
                                    Endereço Comercial
                                </div>
                                <div className={classes.row}>
                                    <FontAwesomeIcon icon={faEnvelope} className={classes.icon} />
                                    Gestão de Correspondência
                                </div>
                                <div className={classes.row}>
                                    <FontAwesomeIcon icon={faMobileAlt} className={classes.icon} />
                                    Aplicativo CW
                                </div>
                            </div>
                            <button className={classes.btn} onClick={this.props.openForm}>Fale com o consultor</button>
                        </div>
                        <div className={classes.card}>
                            <h2 className={classes.cardTitle}>Salas Privativas</h2>
                            <div className={classes.preco} style={{ 'fontSize': '0.8rem' }}>
                                R$<span>549,00</span>/Estação de Trabalho
                            </div>
                            <div className={classes.cardBody}>
                                <div className={classes.row}>
                                    <FontAwesomeIcon icon={faUsers} className={classes.icon} />
                                    Sala para 2 pessoas
                                </div>
                                <div className={classes.row}>
                                    <FontAwesomeIcon icon={faUsers} className={classes.icon} />
                                    Sala para 3 pessoas
                                </div>
                                <div className={classes.row}>
                                    <FontAwesomeIcon icon={faUsers} className={classes.icon} />
                                    Sala para 4 Pessoas
                                </div>
                                <div className={classes.row}>
                                    <FontAwesomeIcon icon={faUsers} className={classes.icon} />
                                    Sala para 6 Pessoas
                                </div>
                            </div>
                            <button className={classes.btn} onClick={this.props.openForm}>Fale com o consultor</button>
                        </div>
                        <div className={classes.card}>
                            <h2 className={classes.cardTitle}>Salas Compartilhadas</h2>
                            <div className={classes.preco}>
                                R$<span>549,00</span>/mês
                            </div>
                            <div className={classes.cardBody}>
                                <div className={classes.row}>
                                    <FontAwesomeIcon icon={faBriefcase} className={classes.icon} />
                                    Estação de Trabalho
                                </div>
                                <div className={classes.row}>
                                    <FontAwesomeIcon icon={faWifi} className={classes.icon} />
                                    Internet
                                </div>
                                <div className={classes.row}>
                                    <FontAwesomeIcon icon={faKey} className={classes.icon} />
                                    Uso do Locker
                                </div>
                                <div className={classes.row}>
                                    <FontAwesomeIcon icon={faMobileAlt} className={classes.icon} />
                                    Aplicativo CW
                                </div>
                            </div>
                            <button className={classes.btn} onClick={this.props.openForm}>Fale com o consultor</button>
                        </div>

                        <div className={classes.card}>
                            <h2 className={classes.cardTitle}>Gestão de Agenda</h2>
                            <div className={classes.preco}>
                                R$<span>499,00</span>/mês
                            </div>
                            <div className={classes.cardBody}>
                                <div className={classes.row}>
                                    <FontAwesomeIcon icon={faPhone} className={classes.icon} />
                                    Telefone Personalizado
                                </div>
                                <div className={classes.row}>
                                    <FontAwesomeIcon icon={faCalendarCheck} className={classes.icon} />
                                    Gestão de Agenda
                                </div>
                                <div className={classes.row}>
                                    <FontAwesomeIcon icon={faMobileAlt} className={classes.icon} />
                                    Aplicativo de Agenda CW
                                </div>
                            </div>
                            <button className={classes.btn} onClick={this.props.openForm}>Fale com o consultor</button>
                        </div>
                        <div className={classes.card}>
                            <h2 className={classes.cardTitle}>Telefonia e Atendimento</h2>
                            <div className={classes.preco}>
                                R$<span>259,00</span>/mês
                            </div>
                            <div className={classes.cardBody}>
                                <div className={classes.row}>
                                    <FontAwesomeIcon icon={faPhone} className={classes.icon} />
                                    Telefone Personalizado
                                </div>
                                <div className={classes.row}>
                                    <FontAwesomeIcon icon={faUserCheck} className={classes.icon} />
                                    Atendimento Personalizado
                                </div>
                                <div className={classes.row}>
                                    <FontAwesomeIcon icon={faMobileAlt} className={classes.icon} />
                                    Aplicativo de Agenda CW
                                </div>
                            </div>
                            <button className={classes.btn} onClick={this.props.openForm}>Fale com o consultor</button>
                        </div>
                        <div className={classes.card}>
                            <h2 className={classes.cardTitle}>Sala de Reunião/Auditório</h2>
                            <div className={classes.preco}>
                                R$<span>65,00 a 150,00</span>/hora
                            </div>
                            <div className={classes.cardBody}>
                                <div className={classes.row}>
                                    <FontAwesomeIcon icon={faBullhorn} className={classes.icon} />
                                    Mini-auditório
                                </div>
                                <div className={classes.row}>
                                    <FontAwesomeIcon icon={faUserCheck} className={classes.icon} />
                                    Atendimento Personalizado
                                </div>
                                <div className={classes.row}>
                                    <FontAwesomeIcon icon={faDoorOpen} className={classes.icon} />
                                    Salas de Reunião
                                </div>
                            </div>
                            <button className={classes.btn} onClick={this.props.openForm}>Fale com o consultor</button>
                        </div>

                    </div>

                    <div className={classes.bottom}>
                        <p>
                            <FontAwesomeIcon icon={faCheck} className={classes.icon} />
                            O CafeWorking possui diversas salas compartilhadas e privativas para que seus clientes possam alugar por um baixo preço.
                        </p>
                        <p>
                            <FontAwesomeIcon icon={faCheck} className={classes.icon} />
                            Clientes do CafeWorking terão descontos na Ciatos Coworking.
                        </p>
                        <div style={{ margin: '0 auto', width: 'max-content' }}>
                            <button className={classes.btnBottom} onClick={this.props.openForm}>Solicite um contato</button>
                        </div>
                    </div>
                </div>
            </section>
        );
    }
}

export default SectionF;