import React, { Component } from 'react';
import classes from './Subscriber.module.css';

class Subscriber extends Component {

    openForm = () => {
        const form = document.getElementById('form');
        form.style.display = 'unset';
    }

    render() {
        return (
            <React.Fragment>
                <div className={classes.subscriber}>
                    <div>
                        <h4 className={classes.title}>Mantenha-se atualizado</h4>
                        <p className={classes.paragraph}>Nossos novos artigos serão enviados diretamente prara você</p>
                    </div>
                    <div>
                        {/* <input className={classes.emailField} type="email" placeholder="Email" /> */}
                        <input className={classes.btn} type="button" value="Assinar" onClick={this.openForm} />
                    </div>
                </div>
            </React.Fragment>
        );
    }
}

export default Subscriber;