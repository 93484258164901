import React from 'react';

import c from './UseTerms.module.css'

import iconClose from '../../assets/img/icons/close-white.svg'
import iconLosango from '../../assets/img/icons/losango.svg'

function UseTerms(props) {

    return (
        <div id="use-terms" className={c["use-terms"]}>
            <div className={c["shadow"]}>
                <div className={c["content"]}>

                    <button className={c["btn-close"]} onClick={() => props.close('useTermsDisplayControl')}>
                        <img src={iconClose} alt="Fechar" />
                    </button>

                    <div className={c["header"]}>
                        <p>Termos de Uso</p>
                    </div>

                    {/*<div className={c["body"]}>
                        <p>O Grupo Ciatos Restruturações Empresariais, constituído pelas empresas Ciatos Jurídico, Ciatos Contabilidade,
                        Ciatos Consultoria e Coworking, proprietário do domínio <a class="link" href="https://grupociatos.com.br/">grupociatos.com.br</a>,
                        estabelece o presente “Termo e Condições de Uso” para os usuários conforme as condições abaixo discriminadas:</p>

                        <div class="item">
                            <div class="icon-wrapper">
                                <img src={iconLosango} alt="Losango" className="item-termos-uso" />
                            </div>
                            <p>&nbsp;&nbsp;O Termo e Condições de Uso do site Grupo Ciatos é aplicável ao uso dos serviços oferecidos
                            pelas empresas que compõem o Grupo Ciatos Reestruturações Empresariais.</p>
                        </div>

                        <div class="item">
                            <div class="icon-wrapper">
                                <img src={iconLosango} alt="Losango" className="item-termos-uso" />
                            </div>
                            <p>&nbsp;&nbsp;Qualquer pessoa, legalmente capaz, denominada Usuário que utilize os serviços de qualquer
                            das empresas do Grupo Ciatos, por meio do site, deve aceitar este Termo e Condições de Uso e a Política
                            de Privacidade disponível no website.</p>
                        </div>

                        <div class="item">
                            <div class="icon-wrapper">
                                <img src={iconLosango} alt="Losango" className="item-termos-uso" />
                            </div>
                            <p>&nbsp;&nbsp;O website do Grupo Ciatos, localizado no endereço eletrônico <a class="link" href="https://grupociatos.com.br/">grupociatos.com.br</a>,
                            tem como objetivo fornecer informações institucionais sobre os serviços prestados nas áreas de
                            Consultoria, Jurídica, Contabilidade, Coworking, entre outros.</p>
                        </div>

                        <div class="item">
                            <div class="icon-wrapper">
                                <img src={iconLosango} alt="Losango" className="item-termos-uso" />
                            </div>
                            <p>&nbsp;&nbsp;No Blog do website há disponibilização de conteúdo jurídico, composto de artigos de autoria
                            dos advogados da Ciatos Jurídico, devendo ser interpretado como material informativo, e tais artigos
                            não devem ser considerados como orientação legal para nenhum caso concreto, na medida em que a Ciatos
                            Jurídico não presta serviços advocatícios a partir do site. Orientações legais específicas devem
                            ser obtidas através de nossos advogados e consultores. </p>
                        </div>

                        <div class="item">
                            <div class="icon-wrapper">
                                <img src={iconLosango} alt="Losango" className="item-termos-uso" />
                            </div>
                            <p>&nbsp;&nbsp;Direitos Autorais: Todos os materiais, textos, marcas, gráficos exibidos no website estão
                            protegidos pelas leis de Propriedade Intelectual e não podem ser reproduzidos e/ou distribuídos sem
                            a expressa autorização de Grupo Ciatos.</p>
                        </div>

                        <div class="item">
                            <div class="icon-wrapper">
                                <img src={iconLosango} alt="Losango" className="item-termos-uso" />
                            </div>
                            <p>&nbsp;&nbsp;O compartilhamento das informações por e-mail ou redes sociais, realizado através dos recursos
                            disponibilizados no site não viola direitos autorais.</p>
                        </div>

                        <div class="item">
                            <div class="icon-wrapper">
                                <img src={iconLosango} alt="Losango" className="item-termos-uso" />
                            </div>
                            <p>&nbsp;&nbsp;O Grupo Ciatos não poderá, em hipótese alguma, ser responsabilizado perante qualquer pessoa,
                            por qualquer tipo de perda, dano, custo ou despesa resultante de eventuais erros, omissões, ou
                            alterações nas informações fornecidas pelo website, nem tampouco por quaisquer atrasos, inexatidões,
                            erros, ou interrupções ocasionados em função destes eventos, durante o suprimento de qualquer
                            informação através das páginas do site.</p>
                        </div>

                        <div class="item">
                            <div class="icon-wrapper">
                                <img src={iconLosango} alt="Losango" className="item-termos-uso" />
                            </div>
                            <p>&nbsp;&nbsp;Informações de Terceiros: O Grupo Ciatos não se responsabiliza por informações publicadas em
                            links externos que estejam contidos neste website.</p>
                        </div>

                        <div class="item">
                            <div class="icon-wrapper">
                                <img src={iconLosango} alt="Losango" className="item-termos-uso" />
                            </div>
                            <p>&nbsp;&nbsp;Da mesma forma, o Grupo Ciatos não pode garantir a precisão de informações relacionadas a
                            eventos organizados por terceiros.</p>
                        </div>

                        <div class="item">
                            <div class="icon-wrapper">
                                <img src={iconLosango} alt="Losango" className="item-termos-uso" />
                            </div>
                            <p>&nbsp;&nbsp;O Usuário, ao cadastrar-se no site do Grupo Ciatos, manifesta conhecer e poder exercitar os
                            direitos de cancelar o cadastro realizado por ele e acessar e atualizar os dados pessoais, nos moldes
                            da Politica de Privacidade.</p>
                        </div>

                        <div class="item">
                            <div class="icon-wrapper">
                                <img src={iconLosango} alt="Losango" className="item-termos-uso" />
                            </div>
                            <p>&nbsp;&nbsp;Qualquer tipo de informação enviada ao Grupo Ciatos através dos meios de comunicação disponíveis
                            no site, não será tratada como confidencial. Comunicações realizadas com a Ciatos Jurídico, também por
                            meio de comunicação disponível no website, não constituirão relação cliente/ advogado.</p>
                        </div>

                        <div class="item">
                            <div class="icon-wrapper">
                                <img src={iconLosango} alt="Losango" className="item-termos-uso" />
                            </div>
                            <p>&nbsp;&nbsp;A aceitação do Termo e Condições de Uso do Grupo Ciatos é obrigatória para o acesso aos serviços
                            prestados através do domínio <a class="link" href="https://grupociatos.com.br/">grupociatos.com.br</a>.</p>
                        </div>

                        <div class="item">
                            <div class="icon-wrapper">
                                <img src={iconLosango} alt="Losango" className="item-termos-uso" />
                            </div>
                            <p>&nbsp;&nbsp;O Grupo Ciatos poderá alterar, a qualquer tempo, este Termo, visando seu aprimoramento, adaptação
                            a novas legislações e melhoria dos serviços prestados.</p>
                        </div>

                        <div class="item">
                            <div class="icon-wrapper">
                                <img src={iconLosango} alt="Losango" className="item-termos-uso" />
                            </div>
                            <p>&nbsp;&nbsp;As partes elegem o foro da Comarca de Belo Horizonte como competente para dirimir quaisquer
                            controvérsias decorrentes deste “Temo de Uso”, A legislação brasileira é aplicável para reger e interpretar
                            este Termo de Uso.</p>
                        </div>
    </div>*/}
                </div>
            </div>
        </div>
    )
}

export default UseTerms