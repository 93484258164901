import React, { Component } from 'react';
import c from './CookiesAdvice.module.css';

class CookiesAdvice extends Component {

    state = {
        show: {
            display: 'flex'
        }
    }

    componentDidMount() {
        if (localStorage.getItem('pureJavaScriptCookies')) {
            this.setState({ show: { display: 'none' } })
        }
    }

    acceptCookies = () => {
        localStorage.setItem("pureJavaScriptCookies", "accept");
        this.setState({ show: { display: 'none' } })
    };

    render() {
        return (
            <div className={c.frame} style={this.state.show}>
                <p className={c.text}>Este site usa cookies para garantir que você obtenha a melhor experiência.</p>
                <button className={c.btn} onClick={this.acceptCookies}>OK</button>
            </div>
        )
    }
}

export default CookiesAdvice;