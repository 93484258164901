import React, { Component } from 'react';
import classes from './SectionH.module.css';
import check from '../../assets/img/check.png';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCheck } from '@fortawesome/free-solid-svg-icons';

class SectionH extends Component {

    render() {
        return (
            <section id="H" className={classes.section}>
                <div className={classes.container}>
                    <h1 className={classes.title}>Diferenciais e Vantagens</h1>
                    <div className={classes.decorator}></div>

                    <div className={classes.content}>
                        <div className={classes.imageContainer}>
                            <div className={classes.imageWrapper}>
                                <img src={check} alt="Check" />
                            </div>
                        </div>
                        <div>
                            <p className={classes.text}>Sem burocracias, despesas com aluguel, condomónio e assinaturas de serviços de internet e telefonia, estrutura montada e tecnológica, ambiente profissional, com possibilidade de interação com vários segmentos e ampliação de rede de contatos e negócios, livre das distrações e adaptações do home-office.</p>
                            <div className={classes.items}>
                                <div className={classes.itemGroup}>
                                    <div className={classes.item}>
                                        <FontAwesomeIcon icon={faCheck} className={classes.icon} />
                                        Cafeteria exclusiva
                                    </div>
                                    <div className={classes.item}>
                                        <FontAwesomeIcon icon={faCheck} className={classes.icon} />
                                        Acessibilidade
                                    </div>
                                    <div className={classes.item}>
                                        <FontAwesomeIcon icon={faCheck} className={classes.icon} />
                                        Design moderno
                                    </div>
                                </div>
                                <div className={classes.itemGroup}>
                                    <div className={classes.item}>
                                        <FontAwesomeIcon icon={faCheck} className={classes.icon} />
                                        Salas de reunião equipadas
                                    </div>
                                    <div className={classes.item}>
                                        <FontAwesomeIcon icon={faCheck} className={classes.icon} />
                                        Gestão por aplicativo
                                    </div>
                                    <div className={classes.item}>
                                        <FontAwesomeIcon icon={faCheck} className={classes.icon} />
                                        Contratos e horários flexíveis
                                    </div>
                                </div>
                                <div className={classes.itemGroup}>
                                    <div className={classes.item}>
                                        <FontAwesomeIcon icon={faCheck} className={classes.icon} />
                                        Ambiente amplo e uma vista privilegiada
                                    </div>
                                    <div className={classes.item}>
                                        <FontAwesomeIcon icon={faCheck} className={classes.icon} />
                                        Segurança
                                    </div>
                                    <div className={classes.item}>
                                        <FontAwesomeIcon icon={faCheck} className={classes.icon} />
                                        Auditórios para palestras, treinamentos e cursos
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>

                    <p className={classes.text}>Estamos de portas abertas para receber você, vamos tomar um café?</p>
                </div>
            </section>
        );
    }
}

export default SectionH;