import React, { Component } from 'react';
import classes from './Footer.module.css';
import arvore from '../../assets/img/logo-grupo-ciatos.png';

class Footer extends Component {

    render() {
        return (
            <div className={classes.container}>
                <div className={classes.row}>
                    <div className={classes.column}>
                        <a className={classes.link} href="#a">Missão, Visão e Valores</a>
                        <a className={classes.link} href="#a">Política de Privacidade</a>
                        <a className={classes.link} href="#a">Termos de Uso</a>
                    </div>

                    <img className={classes.image} src={arvore} alt="Arvore do Grupo Ciatos" />

                    <div className={classes.column}>
                        <a className={classes.link} href="/#B">Conheça o CafeWorking</a>
                        <a className={classes.link} href="/#C">Onde estamos localizados</a>
                        <a className={classes.link} href="/#H">Diferencias e vantagens do nosso coworking</a>
                    </div>
                </div>

            </div>
        );
    }
}

export default Footer;
