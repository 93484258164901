import React from 'react'
import './style.css'
import logo from '../../assets/img/logo.png'

function ArticleCard(props) {
    const { title, excerpt, date, author, imgUrl, slug } = props.article
    const excerptHtml = {
        __html: excerpt
    }
    return (
        <a className="card-link no-text-decoration" href={`/blog/article/${slug}`}>
            <div className="card">
                <div className="image-wrapper">
                    <img src={imgUrl ?? 'https://www.rosalesmiami.com/images/common-images/no-image.png'} alt="Post" />
                </div>
                <div className="content">
                    <div className="badge">
                        <img src={logo} alt="Logo do Grupo Ciatos"/>
                    </div>
                    <p className="title">{title}</p>
                    <p className="excerpt" dangerouslySetInnerHTML={excerptHtml}></p>
                    <div className="info">
                        <span className="info-item">Por &nbsp;{author}</span>
                        <div className="divider"></div>
                        <span className="info-item">{date}</span>
                    </div>
                </div>
            </div>
        </a>
    )
}

export default ArticleCard
