import React, { Component } from "react";
import { Route } from "react-router";
import searchIcon from '../../assets/img/icons/search.svg'
import searchIconWhite from '../../assets/img/icons/search-white.svg'

import './style.css'

class SearchArticle extends Component {
    constructor() {
        super()
        this.state = {
            search: '',
        }

        this.handleChange = this.handleChange.bind(this)
        this.handleSubmit = this.handleSubmit.bind(this)
    }

    handleChange(event) {
        const { value } = event.target
        this.setState({
            search: value
        })
    }

    handleSubmit(history) {
        const { search } = this.state
        // const articles = await searchArticles(search)
        // console.log(search)
        // history.push(`/blog/search/${search}`)
    }


    render() {
        const icon = this.props.background === 'background-white' ? searchIcon : searchIcon

        return (
            <div className="search-field">
                <Route render={({ history }) => (
                    <form onSubmit={(event) => {
                        // event.preventDefault()
                        this.handleSubmit(history)
                    }}>
                        <input disabled
                            className={this.props.background}
                            type="text"
                            placeholder="Buscar"
                            name="search"
                            value={this.state.search}
                            onChange={this.handleChange} />
                        <button className="btn-search"><img src={icon} alt="Search" /></button>
                    </form>
                )} />
            </div>

        )
    }
}

export default SearchArticle