import React, { Component } from 'react';
import classes from './SectionG.module.css';
import lounge from '../../assets/img/lounge.png';
import auditorio from '../../assets/img/auditorio.png';
import cafeteria from '../../assets/img/cafeteria.png';
import salaReuniao from '../../assets/img/sala-reuniao.png';
import salaCompartilhada from '../../assets/img/salas-compartilhadas.png';
import salaPrivativa from '../../assets/img/salas-privativas.png';

class SectionG extends Component {

    render() {
        return (
            <section id="nossos-espacos" className={classes.section}>
                <div className={classes.container}>
                    <h1 className={classes.title}>Conheça nossos espaços</h1>
                    <div className={classes.decorator}></div>
                    <div className={classes.cards}>
                        <div className={classes.card} onClick={() => { this.props.openModalEspacos(0) }}>
                            <h2 className={classes.cardTitle}>Lounge</h2>
                            <div className={classes.cardDecorator}></div>
                            <div className={classes.imageContainer}>
                                <div className={classes.imageWrapper}>
                                    <img src={lounge} alt="Lounge" />
                                </div>
                            </div>
                        </div>
                        <div className={classes.card} onClick={() => { this.props.openModalEspacos(1) }}>
                            <h2 className={classes.cardTitle}>Salas Privativas</h2>
                            <div className={classes.cardDecorator}></div>
                            <div className={classes.imageContainer}>
                                <div className={classes.imageWrapper}>
                                    <img src={salaPrivativa} alt="Privativa" />
                                </div>
                            </div>
                        </div>
                        <div className={classes.card} onClick={() => { this.props.openModalEspacos(2) }}>
                            <h2 className={classes.cardTitle}>Salas Compartilhadas</h2>
                            <div className={classes.cardDecorator}></div>
                            <div className={classes.imageContainer}>
                                <div className={classes.imageWrapper}>
                                    <img src={salaCompartilhada} alt="Compartilhada" />
                                </div>
                            </div>
                        </div>

                        <div className={classes.card} onClick={() => { this.props.openModalEspacos(3) }}>
                            <h2 className={classes.cardTitle}>Sala de Reunião</h2>
                            <div className={classes.cardDecorator}></div>
                            <div className={classes.imageContainer}>
                                <div className={classes.imageWrapper}>
                                    <img src={salaReuniao} alt="Reuniao" />
                                </div>
                            </div>
                        </div>
                        <div className={classes.card} onClick={() => { this.props.openModalEspacos(4) }}>
                            <h2 className={classes.cardTitle}>Cafeteria</h2>
                            <div className={classes.cardDecorator}></div>
                            <div className={classes.imageContainer}>
                                <div className={classes.imageWrapper}>
                                    <img src={cafeteria} alt="cafeteria" />
                                </div>
                            </div>
                        </div>
                        <div className={classes.card}>
                            <h2 className={classes.cardTitle}>Auditório</h2>
                            <div className={classes.cardDecorator}></div>
                            <div className={classes.imageContainer}>
                                <div className={classes.imageWrapper}>
                                    <img src={auditorio} alt="Auditorio" />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        );
    }
}

export default SectionG;