import React, { Component } from 'react';
import classes from './SectionB.module.css';

class SectionB extends Component {

    render() {
        return (
            <section id="cafeworking" className={classes.section}>
                <div className={classes.container}>
                    <div className={classes.column}>
                        <h1 className={classes.title}>CafeWorking</h1>
                        <div className={classes.decorator}></div>
                        <p className={classes.text}>
                            O CafeWorking é um escritório compartilhado em Belo Horizonte, ideal para quem deseja focar no seu trabalho com tranquilidade e qualidade, sem se preocupar
                            com a administração ou com o alto custo de manutenção.<br />Nosso espaço é estruturado para funcionamento de empresas e profssionais autônomos desempenharem suas
                            atividades.<br />Além de oferecermos uma infraestrutura completa, com salas de reunião, escritórios privativos e estações de trabalho compartilhadas, proporcionamos
                            um ambiente estimulante e harmonizado com uma confortável cafeteria, onde você poderá encontrar economia, bom gosto e praticidade em um só lugar!
                        </p>
                    </div>
                </div>
            </section>
        );
    }
}

export default SectionB;