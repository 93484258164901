import React, { Component } from 'react';
import classes from './Cafeteria.module.css';

class Cafeteria extends Component {

    render() {
        return (
            <section id="cafeteria" className={classes.section}>
                <div className={classes.transparent}>
                    <div className={classes.content}>
                        <h2 className={classes.title}>A CAFETERIA</h2>
                        <div className={classes.decorator}></div>
                        <p className={classes.text}>Na nossa cafeteria CafeWorking você poderá encontrar ambiente descontraído e aconchegante, perfeito
                        para relaxar e tomar uma café! Aqui temos um abiente ideal para  networking ou para um momento de pausa.
                        Além de deliciosos cafés, oferecemos lanches, pratos indivi-duais, bons livros, músicas agradáveis.</p>
                        <span className={classes.textBottom}>Seja muito bem-vindo e sinta-se à vontade!</span>
                    </div>
                </div>
            </section>
        );
    }
}

export default Cafeteria;