import React, { Component } from 'react';
import Footer from '../footer/Footer';
import Form from '../form/Form';
import Navbar from '../navbar/Navbar';
import SectionA from '../section-a/SectionA';
import SectionB from '../section-b/SectionB';
import SectionC from '../section-c/SectionC';
import Cafeteria from '../section-cafeteria/Cafeteria';
import Cafeteria2 from '../section-cafeteria2/Cafeteria2';
import SectionF from '../section-f/SectionF';
import SectionG from '../section-g/SectionG';
import SectionH from '../section-h/SectionH';
import Contabilidade from '../section-contabilidade/Contabilidade';
import Consultoria from '../section-consultoria/Consultoria';
import FeaturedLinks from '../../components/featured-links/FeaturedLinks';
import ModalFotos from '../modal-fotos/ModalFotos';
import ModalFotosEspacos from '../modal-fotos-espacos/ModalFotosEspacos';
import CookiesAdvice from '../cookies-advice/CookiesAdvice';
import PrivacyPolicy from '../privacy-policy/PrivacyPolicy';
import UseTerms from '../use-terms/UseTerms';
import WhatsappLink from '../whatsapp-link/WhatsappLink';

class Home extends Component {

    constructor() {
        super();
        this.state = {
            showModal: false,
            index: 0,
            useTermsDisplayControl: 'hide',
            privacyPolicyDisplayControl: 'hide',
        }
        document.body.style.overflowY = 'scroll';
    }

    openForm = () => {
        const form = document.getElementById('form');
        form.style.display = 'unset';
    }

    openModal = () => {
        document.body.style.overflowY = 'hidden';
        const modal = document.getElementById('modalFotos');
        modal.style.display = 'flex';
    }

    openModalEspacos = (index) => {
        this.setState({index: index})
        document.body.style.overflowY = 'hidden';
        const modal = document.getElementById('modalFotosEspacos');
        modal.style.display = 'flex';
    }

    showModal = (param) => {
        this.setState({ [param]: '' })
    }

    hideModal = (param) => {
        this.setState({ [param]: 'hide' })
    }

    render() {
        let modalEspacos = <ModalFotosEspacos index={0} />;
        if(this.state.index === 1){
            modalEspacos = <ModalFotosEspacos index={1} />;
        }
        if(this.state.index === 2){
            modalEspacos = <ModalFotosEspacos index={2} />;
        }
        if(this.state.index === 3){
            modalEspacos = <ModalFotosEspacos index={3} />;
        }
        if(this.state.index === 4){
            modalEspacos = <ModalFotosEspacos index={4} />;
        }
        if(this.state.index === 5){
            modalEspacos = <ModalFotosEspacos index={5} />;
        }
        if(this.state.index === 6){
            modalEspacos = <ModalFotosEspacos index={6} />;
        }
        return (
            <div>
                <div className={`${this.state.privacyPolicyDisplayControl}`}>
                    <PrivacyPolicy close={this.hideModal} />
                </div>
                <div className={`${this.state.useTermsDisplayControl}`}>
                    <UseTerms close={this.hideModal} />
                </div>
                <WhatsappLink />
                <CookiesAdvice />
                <ModalFotos show={this.state.showModal} />
                { modalEspacos }
                <FeaturedLinks />
                <Navbar openForm={this.openForm} />
                <SectionA openForm={this.openForm} />
                <SectionB openForm={this.openForm} />
                <Cafeteria openForm={this.openForm} />
                <Cafeteria2 openForm={this.openForm} openModal={this.openModal} />
                <SectionF openForm={this.openForm} />
                <Contabilidade openForm={this.openForm} />
                <Consultoria openForm={this.openForm} />
                <SectionG openForm={this.openForm} openModalEspacos={this.openModalEspacos}/>
                <SectionC openForm={this.openForm} />
                <SectionH openForm={this.openForm} />
                <Footer openForm={this.openForm} showModal={this.showModal} />
                <Form />
            </div>
        );
    }
}

export default Home;