const API_URL = "https://cafeworking.com.br/wordpress/wp-json/wp/v2/"

export async function getCategoryById(id) {
    return fetch(`${API_URL}categories/${id}`)
        .then(res => res.json())
        // .then(data => console.log(data))
        .catch(err => console.error('Error: ', err))
}

export async function getCategories() {
    const url = `${API_URL}categories`
    return fetch(url)
        .then(res => res.json())
        .then(res => res.filter(c => c.id !== 1))
        .catch(err => console.error('Error: ', err))
}
