import React, { Component } from 'react';
import classes from './Article.module.css';
import img from '../../assets/img/slide2.jpg';

function Article(props) {
    const { article } = props;

    const displayImg = article._embedded['wp:featuredmedia']
        ? article._embedded['wp:featuredmedia'][0].source_url
        : img;

    const author = article._embedded.author
        ? article._embedded.author[0].name
        : '...'

    const date = article.date.split('T')[0].split('-').reverse().join('/');

    return (
        <React.Fragment>
            <div className={classes.article}>
                <div
                    className={classes.imageWrapper}
                    style={{
                        background: `url(${displayImg})`,
                        backgroundPosition: 'center',
                        backgroundSize: 'cover',
                    }}>
                </div>
                <div className={classes.content}>
                    <h3 className={classes.title}>{article.title.rendered}</h3>
                    <h4 className={classes.description}
                        dangerouslySetInnerHTML={{ __html: article.excerpt.rendered }}></h4>
                </div>
                <div className={classes.footer}>
                    <button className={classes.btn}>
                        <a className={classes.link} href={`/blog/article/${article.id}`}> Ler Artigo </a>
                    </button>
                    <div className={classes.metaData}>
                        <p className={classes.autor}>{author}</p>
                        <p className={classes.date}>{date}</p>
                    </div>
                </div>
            </div>
        </React.Fragment>
    );
}

export default Article;