import React, { Component } from 'react';
import classes from './Form.module.css';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTimes } from '@fortawesome/free-solid-svg-icons';
import { sendEmailModal } from '../../services/email-service.js';
import ClipLoader from 'react-spinners/ClipLoader';

class Form extends Component {

    constructor(props) {
        super(props);
        this.state = {
            name: '',
            phone: '',
            email: '',
            subject: '',
            resultContent: '',
            resultColor: 'success',
            loading: false,
        }
    }

    close = () => {
        const form = document.getElementById('form');
        form.style.display = 'none';
    }

    handleChange = (event) => {
        const { name, value } = event.target;

        this.setState({
            [name]: value,
        })
    }

    handleSubmit = async (event) => {
        event.preventDefault();

        this.setState({
            loading: true
        })

        const name = this.state.name.trim().split(' ');

        const success = await sendEmailModal(
            name.length >= 1 ? name[0] : '',
            name.length >= 2 ? name[1] : '',
            this.state.email,
            this.state.phone,
            this.state.subject,
            'CafeWorking Site',
        )

        if (success) {
            this.setState({
                resultContent: 'Dados enviados com sucesso!',
                resultColor: 'success',
                loading: false
            })
        } else {
            this.setState({
                resultContent: 'Erro ao enviar os dados!',
                resultColor: 'failed',
                loading: false
            })
        }
    }

    render() {
        const { color = '' } = this.props;
        return (
            <div id="form" className={classes.form}>
                <div className={classes.flexbox} >
                    <div className={classes.container} >
                        <div className={classes['titleBar' + color]}>
                            Falar com um consultor
                            <FontAwesomeIcon icon={faTimes} className={classes.icon} onClick={this.close} />
                        </div>
                        <div className={classes.content}>
                            {/* <p style={{ color: 'red', display: 'unset' }}>Serviço indisponível no momento</p> */}
                            <form action="submit" onSubmit={this.handleSubmit}>
                                <label htmlFor="name">Nome</label>
                                <input className={classes.field}
                                    type="text"
                                    name="name"
                                    value={this.state.name}
                                    onChange={this.handleChange}
                                    id="name" />
                                <label htmlFor="phone">Telefone</label>
                                <input className={classes.field}
                                    type="text"
                                    name="phone"
                                    value={this.state.phone}
                                    onChange={this.handleChange}
                                    id="phone" />
                                <label htmlFor="email">Email</label>
                                <input className={classes.field}
                                    type="email"
                                    name="email"
                                    value={this.state.email}
                                    onChange={this.handleChange}
                                    id="email" />
                                <label htmlFor="subject">Assunto</label>
                                <input className={classes.field}
                                    type="text"
                                    name="subject"
                                    value={this.state.subject}
                                    onChange={this.handleChange}
                                    id="subject" />

                                <div className={classes.row}>
                                    <button className={classes.btnClose} onClick={this.close}>Fechar</button>
                                    <button className={classes['btnSubmit' + color]} disabled={this.state.loading} >
                                        {this.state.loading ? <ClipLoader color="#000000" loading={true} size={18} /> : 'Enviar'}
                                    </button>
                                </div>
                            </form>
                            <div className={classes[this.state.resultColor]}>{this.state.resultContent}</div>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}

export default Form;
