import React, { Component } from "react";
import s from './ModalFotos.module.css';
import leftIcon from '../../assets/img/icons/arrow-left.svg';
import rightIcon from '../../assets/img/icons/arrow-right.svg';
import img1 from '../../assets/img/cafe-notebook.jpg';
import img2 from '../../assets/img/business.png';

// cafeteria
import cafeteria01 from '../../assets/img/cafeteria/01.jpeg';
import cafeteria02 from '../../assets/img/cafeteria/02.jpeg';
import cafeteria03 from '../../assets/img/cafeteria/03.jpeg';
import cafeteria04 from '../../assets/img/cafeteria/04.jpeg';
import cafeteria05 from '../../assets/img/cafeteria/05.jpeg';
import cafeteria06 from '../../assets/img/cafeteria/06.jpeg';
import cafeteria07 from '../../assets/img/cafeteria/07.jpeg';
import cafeteria08 from '../../assets/img/cafeteria/08.jpeg';
import cafeteria09 from '../../assets/img/cafeteria/09.jpeg';
import cafeteria10 from '../../assets/img/cafeteria/10.jpeg';
import cafeteria11 from '../../assets/img/cafeteria/11.jpeg';
import cafeteria12 from '../../assets/img/cafeteria/12.jpeg';

// lounge
import lounge01 from '../../assets/img/lounge/01.jpeg';
import lounge02 from '../../assets/img/lounge/02.jpeg';
import lounge03 from '../../assets/img/lounge/03.jpeg';
import lounge04 from '../../assets/img/lounge/04.jpeg';
import lounge05 from '../../assets/img/lounge/05.jpeg';
import lounge06 from '../../assets/img/lounge/06.jpeg';
import lounge07 from '../../assets/img/lounge/07.jpeg';

// salas compartilhadas
import compartilhada01 from '../../assets/img/salas-compartilhadas/01.jpeg';
import compartilhada02 from '../../assets/img/salas-compartilhadas/02.jpeg';
import compartilhada03 from '../../assets/img/salas-compartilhadas/03.jpeg';
import compartilhada04 from '../../assets/img/salas-compartilhadas/04.jpeg';
import compartilhada05 from '../../assets/img/salas-compartilhadas/05.jpeg';
import compartilhada06 from '../../assets/img/salas-compartilhadas/06.jpeg';
import compartilhada07 from '../../assets/img/salas-compartilhadas/07.jpeg';
import compartilhada08 from '../../assets/img/salas-compartilhadas/08.jpeg';
import compartilhada09 from '../../assets/img/salas-compartilhadas/09.jpeg';
import compartilhada10 from '../../assets/img/salas-compartilhadas/10.jpeg';
import compartilhada11 from '../../assets/img/salas-compartilhadas/11.jpeg';

// salas privativas
import privativa01 from '../../assets/img/salas-privativas/01.jpeg';
import privativa02 from '../../assets/img/salas-privativas/02.jpeg';
import privativa03 from '../../assets/img/salas-privativas/03.jpeg';
import privativa04 from '../../assets/img/salas-privativas/04.jpeg';
import privativa05 from '../../assets/img/salas-privativas/05.jpeg';
import privativa06 from '../../assets/img/salas-privativas/06.jpeg';
import privativa07 from '../../assets/img/salas-privativas/07.jpeg';
import privativa08 from '../../assets/img/salas-privativas/08.jpeg';
import privativa09 from '../../assets/img/salas-privativas/09.jpeg';
import privativa10 from '../../assets/img/salas-privativas/10.jpeg';
import privativa11 from '../../assets/img/salas-privativas/11.jpeg';

// salas reuniao
import reniao01 from '../../assets/img/salas-reuniao/01.jpeg';
import reniao02 from '../../assets/img/salas-reuniao/02.jpeg';
import reniao03 from '../../assets/img/salas-reuniao/03.jpeg';
import reniao04 from '../../assets/img/salas-reuniao/04.jpeg';
import reniao05 from '../../assets/img/salas-reuniao/05.jpeg';

class ModalFotos extends Component {

    constructor(props) {
        super(props);
        this.state = {
            selectedAba: 0,
            selectedImage: 0,
            images: this.imgListCafes,
            left: false,
            right: true,
            show: props.show,
        }
    }

    imgListCafes = [cafeteria11, cafeteria12];
    imgListPratos = [];

    selectAba = (imgs, i) => {
        if (imgs.length === 0) {
            return;
        }
        this.setState({ images: imgs, selectedAba: i, selectedImage: 0 });
    }

    onRight = () => {
        if (this.state.images.length - 1 > this.state.selectedImage) {
            this.setState((state, props) => ({
                selectedImage: state.selectedImage + 1,
                right: true,
            }))
        }
    }

    onLeft = () => {
        if (this.state.selectedImage > 0) {
            this.setState((state, props) => ({
                selectedImage: state.selectedImage - 1,
                left: true,
            }))
        }
    }

    showModal = () => {
        document.body.style.overflowY = 'hidden';

    }

    hideModal = () => {
        document.body.style.overflowY = 'scroll';
        const modal = document.getElementById('modalFotos');
        modal.style.display = 'none';
    }

    render() {
        const loading = <div>Carregando...</div>
        if (!this.state.images) {
            return loading;
        }
        return (
            <div id="modalFotos" className={s.transparent} style={{ display: this.state.show ? 'flex' : 'none' }}>
                <div className={s.frame}>
                    <button className={`${s.btnAba} ${this.state.selectedAba === 0 ? s.selectedAba : ''}`} onClick={() => { this.selectAba(this.imgListCafes, 0) }}>Cafés</button>
                    <button className={`${s.btnAba} ${this.state.selectedAba === 1 ? s.selectedAba : ''}`} onClick={() => { this.selectAba(this.imgListPratos, 1) }}>Pratos</button>

                    <div className={s.imgWrapper}>
                        <img src={this.state.images[this.state.selectedImage]} alt="Carrousel" />
                    </div>

                    <button className={s.btnClose} onClick={this.hideModal}>X</button>
                    <button className={s.btnArrow} onClick={this.onLeft}> <img src={leftIcon} alt="Left arrow" /> </button>
                    <button className={s.btnArrow} onClick={this.onRight}> <img src={rightIcon} alt="Right arrow" /> </button>

                </div>
            </div>
        )
    }
}

export default ModalFotos;