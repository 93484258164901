const SEND_EMAIL_API_URL = 'https://ciatos-email-api.herokuapp.com'

export async function sendEmailModal(firstName, lastName, email, phone, message, subject) {

    const myHeaders = new Headers();
    myHeaders.append("Content-Type", "application/json");

    const raw = JSON.stringify({
        "email": email,
        "name": `${firstName} ${lastName}`,
        "phone": phone,
        "message": message,
        "about": subject
    });

    const requestOptions = {
        method: 'POST',
        headers: myHeaders,
        body: raw,
    };

    const success = await fetch(`${SEND_EMAIL_API_URL}/email`, requestOptions)
        .then(res => res.json())
        .then(data => data.success)
        .catch(err => console.log('Error: ', err))

    return success
}