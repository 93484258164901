import React, { Component } from 'react';
import ReactHtmlParser from 'react-html-parser';
import { getArticleById } from '../../services/articleService';

// style
import c from './Slider.module.css';

// icons
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faArrowRight, faArrowLeft } from '@fortawesome/free-solid-svg-icons';

// images
import slide1 from '../../assets/img/o-que-e-o-cafeworking-min.jpeg';


const SliderView = ({ id, title, description, imgUrl, toLeft, toRight }) => {
    return (
        <div className={c.sliderContainer}>
            <div className={c.slider}>
                <div className="imgWrapper">
                    <img src={imgUrl} alt="Artigo" />
                </div>
                <button className={c.btn} onClick={toLeft}><FontAwesomeIcon icon={faArrowLeft} /></button>
                <button className={c.btn} onClick={toRight}><FontAwesomeIcon icon={faArrowRight} /></button>
            </div>
            <div className={c.container}>
                <div className={c.content}>
                    <div>
                        <p className={c.title}>{title}</p>
                        <div className={c.text}>{ReactHtmlParser(description)}</div>
                    </div>
                    <div>
                        <a className={c.link} href={`/blog/article/${id}`}>
                            Conferir <FontAwesomeIcon icon={faArrowRight} />
                        </a>
                    </div>
                </div>
            </div>
        </div>
    )
}

class Slider extends Component {

    constructor() {
        super();

        this.state = {
            selectedSlide: 0,
            articles: [],
            isLoading: true,
        }
    }

    async componentDidMount() {
        let articles = []

        getArticleById(6) // o que e coworking
            .then(res => {
                articles.push(res);
            })

        getArticleById(8) // o que e o cafeworking
            .then(res => {
                articles.push(res);
            })

        getArticleById(11) // dos espacos do cafeworking
            .then(res => {
                articles.push(res);
                articles = articles.map(a => {
                    return {
                        id: a.id,
                        title: a.title.rendered,
                        description: a.excerpt.rendered,
                        imgUrl: a._embedded['wp:featuredmedia'] ? a._embedded['wp:featuredmedia'][0].source_url : slide1,
                    }
                })
                this.setState({ articles: articles, isLoading: false });
            })
    }

    toRight = () => {
        const { articles, selectedSlide } = this.state;
        if (articles.length <= selectedSlide + 1) {
            return;
        }
        this.setState({ selectedSlide: selectedSlide + 1 });
    }

    toLeft = () => {
        const { selectedSlide } = this.state;
        if (selectedSlide <= 0) {
            return;
        }
        this.setState({ selectedSlide: selectedSlide - 1 });
    }

    render() {
        if (this.state.isLoading || this.state.articles.length < 1) {
            return <div>Loading...</div>
        }
        
        const article = this.state.articles[this.state.selectedSlide]
        
        return (
            <SliderView key={article.id} {...article} toLeft={this.toLeft} toRight={this.toRight} />
        );
    }
}

export default Slider;