import React from 'react'
import classes from './FeaturedLinks.module.css'
import facebook from '../../assets/img/icons/facebook-white.svg'
import instagram from '../../assets/img/icons/instagram-white.svg'
// import twitter from '../../assets/img/icons/twitter-white.svg'
import whatsapp from '../../assets/img/icons/whatsapp-white.svg'
// import linkedin from '../../assets/img/icons/linkedin-white.svg'

function FeaturedLinks(props) {
    const { color = '' } = props
    return (
        <div className={classes['featured-links']}>
            <a target="_blank" rel="noreferrer" href="https://www.facebook.com/CafeWorkingoficial/">
                <div className={classes['social-link' + color]}>
                    <img src={facebook} alt="Facebook" />
                </div>
            </a>
            <a target="_blank" rel="noreferrer" href="https://www.instagram.com/cafeworkingoficial/">
                <div className={classes['social-link' + color]}>
                    <img src={instagram} alt="Instagram" />
                </div>
            </a>
            {/* <a target="_blank" rel="noreferrer" href="https://twitter.com/cafeworking">
                <div className={classes['social-link']}>
                    <img src={twitter} alt="Twitter" />
                </div>
            </a> */}
            {/* <a target="_blank" rel="noreferrer" href="https://api.whatsapp.com/send?phone=+5531996571215&text=ola">
                <div className={classes['social-link' + color]}>
                    <img src={whatsapp} alt="Whatsapp" />
                </div>
            </a> */}
            {/* <a target="_blank" rel="noreferrer" href="https://www.linkedin.com/company/cafeworking/">
                <div className={classes['social-link']}>
                    <img src={linkedin} alt="LinkedIn" />
                </div>
            </a> */}

            <div className={classes['featured-email' + color]}>
                atendimento@cafeworking.com.br | (31) 3500-4800
            </div>
        </div>
    )
}

export default FeaturedLinks;