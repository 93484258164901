import React, { Component } from 'react';
import classes from './Footer.module.css';
import logo from '../../assets/img/logo-grupo-ciatos.png'

class Footer extends Component {

    render() {
        return (
            <footer>
                <div className={classes.container}>
                    <div className={classes.left}>
                        {/* <a className={classes.link} href="#a">Missão, visão e valores</a>
                        <div className={classes.decorator}></div> */}
                        <button className={classes.link} onClick={() => this.props.showModal('privacyPolicyDisplayControl')}>Política de Privacidade</button>
                        <div className={classes.decorator}></div>
                        <button className={classes.link} onClick={() => this.props.showModal('useTermsDisplayControl')}>Termos de Uso</button>
                        <div className={classes.decorator}></div>
                    </div>
                    <div className={classes.imageContainer}>
                        <div className={classes.imageWrapper}>
                            <img src={logo} alt="Check" />
                        </div>
                    </div>
                    <div className={classes.right}>
                        <p className={classes.text}>Avenida Raja Gabaglia, nº 2000</p>
                        <p className={classes.text}>Parque Avenida, nª 2000, Estoril</p>
                        <p className={classes.text}>Torre 2, Salas 914/917</p>
                        <p className={classes.text}>Telefone (31) 3500-4800</p>
                        <p className={classes.text}>Whatsapp +55 (31) 9 9657-1215</p>
                        <p className={classes.text}>atendimento@cafeworking.com.br</p>
                    </div>
                </div>
            </footer>
        );
    }
}

export default Footer;