import React, { Component } from 'react';
import classes from './ArticleList.module.css';
import Article from '../article/Article';

class ArticleList extends Component {

    render() {
        return (
            <React.Fragment>
                <div className={classes.container}>
                    {this.props.articles.map(article => {
                        return (
                            <div key={article.id} className={classes.card}>
                                <Article article={article} />
                            </div>
                        )
                    })}
                </div>
            </React.Fragment>
        );
    }
}

export default ArticleList;