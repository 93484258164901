import React, { Component } from 'react';
import classes from './Cafeteria2.module.css';
import homem from '../../assets/img/cafeteria2-homem.png';

class Cafeteria2 extends Component {

    render() {
        return (
            <section id="cafeteria2" className={classes.section}>
                <div className={classes.buttons}>
                    <button className={classes.btn} onClick={this.props.openForm}>Conheça Nossos Cafés</button>
                    <button className={classes.btn} onClick={this.props.openForm}>Conheça Nossos Pratos Especiais</button>
                </div>
                <div className={classes.content}>
                    <div className={classes.leftContent}>
                        <h2 className={classes.title}>QUE TAL TOMAR UM CAFÉ E LER UM BOM LIVRO?</h2>
                        <div className={classes.decorator}></div>
                        <p className={classes.text}>
                            Na cafeteria CafeWorking, enquanto toma um bom café, você poderá aproveitar a pausa para ler um dos nossos diversos livros ou se
                            atualizar das notícias. O CafeWorking disponibiliza aos nossos clientes diversos livros atuais ou, se pre-ferir, conte com nossos
                            jornais! Queremos colaborar para aprimorar seus conheci-mento, aumentar sua criatividade e produtividade.
                        </p>
                    </div>
                    <div className={classes.imgWrapper}>
                        <img src={homem} alt="Figura de um homem sorrindo de óculos." />
                    </div>
                </div>
            </section>
        );
    }
}

export default Cafeteria2;