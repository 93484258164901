import React from 'react'
import ArticleList from "../../components/article-list/ArticleList";
import Header from '../../components/header/Header';
import Footer from '../../components/footer/Footer';
import FeaturedLinks from '../../components/featured-links/FeaturedLinks';
import './style.css'
import WhatsappLink from '../../landing-page/whatsapp-link/WhatsappLink';

function Search(props) {
    const { word } = props.match.params

    return (
        <div className="search-page">
            <FeaturedLinks />
            <WhatsappLink />
            <Header noTransparent={true} />
            <div className="search-content">
                <h1 className="title"> Resultado da pesquisa por "{word}"</h1>
                <ArticleList search={word} />
            </div>
            <Footer />
        </div>
    )
}

export default Search