import React, { Component } from 'react'
import Footer from '../../components/footer/Footer'
import Header from '../../components/header/Header'
import WelcomeSection from '../../components/welcome-section/WelcomeSection'
import ArticleList from '../../components/article-list-2/ArticleList'
import FeaturedLinks from '../../components/featured-links/FeaturedLinks'
import WhatsappLink from '../../landing-page/whatsapp-link/WhatsappLink'
import { getCategoryById } from '../../services/CategoryService'
import './style.css'

class Category extends Component {
    constructor() {
        super()
        this.state = {
            categoryName: 'Categorias'
        }
    }

    async componentDidMount() {
        const category = await getCategoryById(this.props.match.params.id)
        if (category) {
            this.setState({
                categoryName: category.name
            })
        }
    }

    render() {
        return (
            <div id="blog-category" className="category-page">
                <FeaturedLinks />
                <WhatsappLink />
                <Header />
                <WelcomeSection title={this.state.categoryName} />
                <section className="main-section">
                    <ArticleList categoryId={this.props.match.params.id} />
                </section>
                <Footer />
            </div>
        )
    }
}

export default Category