import React, { Component } from 'react';
import '../../main.css';
import classes from './ArticlePage.module.css';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faArrowAltCircleRight, faArrowAltCircleLeft } from '@fortawesome/free-solid-svg-icons';
import ReactHtmlParser from 'react-html-parser';
import avatar from '../../assets/img/sem-foto.png';
import Footer from '../footer/Footer';
import { sendEmailModal } from '../../services/email-service.js';
import ClipLoader from 'react-spinners/ClipLoader';
import { getArticleById } from '../../services/articleService.js';
import WhatsappLink from '../../landing-page/whatsapp-link/WhatsappLink';

class ArticlePage extends Component {

    state = {
        imgUrl: 'https://cdn2.vectorstock.com/i/1000x1000/01/66/businesswoman-character-avatar-icon-vector-12800166.jpg',
        category: 'Empreendedorismo',
        data: {},
        isLoading: true, // article fetch

        // form
        name: '',
        email: '',
        resultContent: '',
        resultColor: 'success',
        loading: false,
    }

    componentDidMount() {

        getArticleById(this.props.match.params.id)
            .then(res => {
                this.setState({ data: res, isLoading: res ? false : true })
            })

    }

    handleChange = (event) => {
        const { name, value } = event.target;

        this.setState({
            [name]: value,
        })
    }

    handleSubmit = async (event) => {
        event.preventDefault();

        this.setState({
            loading: true
        })

        const name = this.state.name.trim().split(' ');

        const success = await sendEmailModal(
            name.length >= 1 ? name[0] : '',
            name.length >= 2 ? name[1] : '',
            this.state.email,
            '',
            this.state.data.title,
            'CafeWorking Blog',
        )

        if (success) {
            this.setState({
                resultContent: 'Dados enviados com sucesso!',
                resultColor: 'success',
                loading: false
            })
        } else {
            this.setState({
                resultContent: 'Erro ao enviar os dados!',
                resultColor: 'failed',
                loading: false
            })
        }
        console.log('', this.resultContent);
    }

    render() {

        const { data, isLoading } = this.state;

        if (isLoading) {
            return (<div>Loading</div>)
        }

        const author = data._embedded ? data._embedded.author[0].name : '...';
        const authorDesc = data._embedded ? data._embedded.author[0].description : '...';
        const date = data.date.split('T')[0].split('-').reverse().join('/');
        const body = data.content.rendered.replaceAll('\\n', '').replaceAll('\\t', '');
        const img = data._embedded['wp:featuredmedia']
            ? data._embedded['wp:featuredmedia'][0].source_url
            : '';

        return (
            <React.Fragment>
                <WhatsappLink />
                <div className={classes.rootContainer}>
                    <div className={classes.propaganda} style={{ display: 'none' }}>
                        <div className={classes.imageContainer}>
                            <img className={classes.image} src="https://community.adobe.com/legacyfs/online/1312304_pastedImage_7.png" alt="" />
                        </div>
                    </div>
                    <div className={classes.content}>
                        <div >
                            <h1 className={classes.title}>{data.title.rendered}</h1>
                            <div className={classes.autor}>
                                <img className={classes.avatar} src={avatar} alt="" />
                                <div>
                                    <p className={classes.autorText}>Por {author}</p>
                                    <p className={classes.autorText}>{authorDesc}</p>
                                    <p className={classes.autorText}>Publicado em {date}</p>
                                </div>
                            </div>

                            <img className={classes.image2} src={`${img}`} alt="" />

                            <div className={classes.articleContent}>
                                {ReactHtmlParser(body)}

                            </div>
                        </div>
                    </div>
                    <div className={classes.row} style={{ display: 'none' }}>
                        <button className={classes.btn2}>
                            <FontAwesomeIcon icon={faArrowAltCircleLeft} className={classes.iconBtn} />
                            <span>Artigo Anterior</span>
                        </button>
                        <div className={classes.sizedBox}></div>
                        <button className={classes.btn2}>
                            <span>Próximo Artigo</span>
                            <FontAwesomeIcon icon={faArrowAltCircleRight} className={classes.iconBtn} />
                        </button>
                    </div>
                    <div className={classes.assine}>
                        <h4 className={classes.assineTitle}>Receba nossos conteúdos em primeira mão</h4>
                        <form action="submit" onSubmit={this.handleSubmit}>
                            <input className={classes.textField}
                                type="text"
                                name="name"
                                id="name"
                                value={this.state.name}
                                onChange={this.handleChange}
                                placeholder="Nome" />
                            <input className={classes.textField}
                                type="email"
                                name="email"
                                id="email"
                                value={this.state.email}
                                onChange={this.handleChange}
                                placeholder="Email" />
                            <input className={classes.btn} type="submit" value="Enviar" />
                        </form>
                        <div>
                            {this.state.loading ? <ClipLoader color="#000000" loading={true} size={20} /> : this.state.resultContent}
                        </div>
                    </div>
                </div>
                <Footer />
            </React.Fragment>
        );
    }
}

export default ArticlePage;