import React, { Component } from 'react';
import classes from './Contabilidade.module.css';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faUsers, faFileInvoiceDollar } from '@fortawesome/free-solid-svg-icons';
import { faStripeS } from '@fortawesome/free-brands-svg-icons';

class Contabilidade extends Component {

    render() {
        return (
            <section id="contabilidade" className={classes.section}>
                <div className={classes.content}>
                    <div className={classes.leftContent}>
                        <h2 className={classes.title}>QUER ABRIR UMA EMPRESA E TER UMA CONTABILIDADE?</h2>
                        <div className={classes.decorator}></div>
                        <p className={classes.text} style={{ marginBottom: '10px' }}>
                            O CaféWorking, além de proporcinar uma experiência incrível aos clientes, através de um espaço de trabalho
                            otimizado e acessível e um bom café, oferece serviços de contabilidade, para pequenos em-presários, a um
                            valor especial:
                        </p>
                        <button className={classes.btn} onClick={this.props.openForm} style={{ marginBottom: '10px' }}>
                            Quer abrir uma empresa?
                        </button>
                        <p>Clientes da Contabilidade não pagam nada pela abertura da empresa!</p>
                    </div>
                    <div className={classes.rightContent}>
                        <div className={classes.card}>
                            <p className={classes.preco}>R$<span>199,00</span>/mês</p>

                            <div className={classes.body}>
                                <p>
                                    <FontAwesomeIcon icon={faStripeS} className={classes.icon} />
                                    &nbsp;&nbsp; Simples Nacional
                                </p>
                                <p>
                                    <FontAwesomeIcon icon={faUsers} className={classes.icon} />
                                    &nbsp;&nbsp; Até 3 funcionários
                                </p>
                                <p>
                                    <FontAwesomeIcon icon={faFileInvoiceDollar} className={classes.icon} />
                                    &nbsp;&nbsp; Até 100 Notas fiscais
                                </p>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        );
    }
}

export default Contabilidade;