import React, { Component } from 'react';
import classes from './Consultoria.module.css';

class Consultoria extends Component {

    render() {
        return (
            <section id="consultoria" className={classes.section}>
                <div className={classes.transparent}>
                    <div className={classes.content}>
                        <div className={classes.leftContent}>
                            <h2 className={classes.title}>ESTÁ COM PROBLEMAS E PRECISA DE CONSULTORIA EMPRESARIAL OU JURÍDICA?</h2>
                            <div className={classes.decorator}></div>
                            <p className={classes.text} style={{ marginBottom: '10px' }}>
                                O CaféWorking, além de proporcinar uma experiência incrí-vel aos clientes, através de um espaço de
                                trabalho otimizado e acessível e um bom café, oferece serviços de consultoria* emprerial e jurídica,
                                para pequenos e médios empresários, a um valor especial:
                            <br />
                            A Consultoria poderá ocorrer de forma presencial ou virtual, dependendo da opção do cliente.
                        </p>
                            <button className={classes.btn} onClick={this.props.openForm} style={{ marginBottom: '10px' }}>
                                Quer agendar uma consulta?
                        </button>
                        </div>
                        <div className={classes.rightContent}>
                            <div className={classes.card}>
                                R$<span>99,00</span>/hora
                        </div>
                        </div>
                    </div>
                </div>
            </section>
        );
    }
}

export default Consultoria;